<template>
  <div
    class="d-flex align-start my-4" >
    <div>
      <v-avatar size="50">
        <v-img 
          sizes="50"
          contain
          :src="
            comment.user.profile.profile_pic
              ? comment.user.profile.profile_pic
              : 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
          "
          alt="avatar"
        ></v-img>
      </v-avatar>
    </div>
    <div class="ml-3">
      <p class="subtitle-1 font-weight-bold mb-0">
        {{ comment.user.username }}
      </p>
      <p class="caption text-muted mb-0">
        {{ moment(comment.created_time).format("MMMM Do YYYY, h:mm:ss a") }}
      </p>
      <p class="mt-3 mb-5">
        {{ comment.comment }}
      </p>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Comment",
  props: {
    comment: Object,
  },
  methods: {
    moment
  }
};
</script>