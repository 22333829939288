
// var SUCCESS_CODES = "200, 201, 204";

// export const BACKEND_URL = "http://127.0.0.1:8000";
// export const BACKEND_URL = "https://artemisvision.herokuapp.com";
export const BACKEND_URL = "https://d264tekgsemw28.cloudfront.net"

export const METAVERSE_URL = "https://metaverse.artemisvision.shop/"


export const IPFS_MEDIA_URL = "https://gateway.pinata.cloud/ipfs/"
export const S3_URL = "https://artemisvision.s3.us-east-2.amazonaws.com/"
export const APP_URL = window.location.href.split("#")[0] //  "https://artemis-vision-app.web.app";
// export const REELS_SERVER  =   "https://d19byhmzy086sf.cloudfront.net/"; // server 2 (1GB ram)
export const REELS_SERVER  =   "https://d1md6ua1idmiv0.cloudfront.net/"; // server 1 (2GB ram)
// export const REELS_SERVER  =   "http://127.0.0.1:8000/";

//
export const BLOCKCHAIN_NETWORK = 'rinkeby'
