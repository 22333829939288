<template>
  <v-app-bar app fixed color="background" elevate-on-scroll>
    <v-container>
      <v-row align="center">
        <a href="/#/">
          <v-img alt="artemisvision" class="d-none d-sm-flex shrink mt-1" contain min-width="100px"
            :src="$vuetify.theme.dark ? whiteLogo : logo" width="150px" />
          <v-img alt="artemisvision" class="d-flex d-sm-none shrink mt-1" contain min-width="100px"
            :src="$vuetify.theme.dark ? whiteLogo : logo" width="150px" />
        </a>
        <v-spacer></v-spacer>

        <!-- <v-text-field
            class="d-none d-sm-flex shrink"
            label="Search"
            placeholder="Search"
            solo
            background-color="#ce45f70"
            flat
            hide-details
            color="#ce45f7"
            dense
            single-line
            prepend-inner-icon="mdi-magnify"
          ></v-text-field> -->
        <!-- <v-btn class="mr-5" small icon @click="openWebAuth">
          <v-img contain width="16px" :src="$vuetify.theme.dark
            ? 'https://web3auth.io/images/w3a-L-Favicon-1.svg'
            : 'https://web3auth.io/images/w3a-D-Favicon-1.svg'
            "></v-img>
        </v-btn> -->
        <!-- <v-menu close-on-click offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-5" small icon v-bind="attrs" v-on="on">
              <v-icon>mdi-wallet</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item dense @click="openWebAuth">
              <v-list-item-avatar rounded size="24px">
                <v-img width="16px" contain :src="$vuetify.theme.dark
                  ? 'https://web3auth.io/images/w3a-L-Favicon-1.svg'
                  : 'https://web3auth.io/images/w3a-D-Favicon-1.svg'
                  "></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Web3 Auth</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <WalletOptionsMenu />

        <v-btn class="mr-4" small @click="
          $vuetify.theme.dark = !$vuetify.theme.dark;
        $store.commit('switchTheme', $vuetify.theme.dark);" :x-small="$vuetify.breakpoint.smAndUp == false"
          :small="$vuetify.breakpoint.smAndUp" icon>
          <v-icon :small="$vuetify.breakpoint.smAndUp == false" v-if="$vuetify.theme.dark == false">mdi-weather-night
          </v-icon>
          <v-icon :small="$vuetify.breakpoint.smAndUp == false" v-else>
            mdi-white-balance-sunny
          </v-icon>
        </v-btn>
        <div class="d-none d-sm-flex shrink">
          <SearchBar />
        </div>

        <!-- hidden-xs-and-down -->
        <v-btn class="d-none d-lg-inline-flex ml-4 primary-gradient" rounded small v-if="Object.keys(user).length === 0"
          color="#CE45F7" depressed to="/login" dark>
          Sign In
        </v-btn>
        <v-btn rounded small depressed color="#CE45F7" class="d-lg-none ml-2 primary-gradient" to="/login"
          v-if="Object.keys(user).length === 0" dark>Sign In</v-btn>
        <v-btn class="d-lg-none ml-2" link icon to="/profile" v-if="Object.keys(user).length !== 0">
          <v-avatar color="primary" size="32">
            <h5 class="mb-0 white--text" v-if="!user.profile.profile_pic">
              <span v-if="user.username != null || user.username != ''">{{
                user.username[0].toUpperCase()
              }}</span><span v-else>{{ user.email[0].toUpperCase() }}</span>
            </h5>
            <v-img sizes="30" contain v-else :src="user.profile.profile_pic"></v-img>
          </v-avatar>
          <!-- <v-icon color="primary">mdi-account</v-icon> -->
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { IPFS_MEDIA_URL } from "../store/config";
import whiteLogo from "../assets/artemis_assets/logo-white.svg";
import logo from "../assets/artemis_assets/logo.svg";
import SearchBar from "./SearchBar";
import WalletOptionsMenu from "./WalletOptionsMenu.vue";
import * as tools from "../store/tools";

export default {
  name: "NavBar",
  components: {
    SearchBar,
    WalletOptionsMenu
  },
  data() {
    return {
      drawer: false,
      whiteLogo,
      logo,
      IPFS_MEDIA_URL: IPFS_MEDIA_URL,
    };
  },
  methods: {
    login() {
      this.$store.commit("login");
    },
    logout() {
      this.$store.commit("logout");
    },
    async openWebAuth() {
      if (!tools.torusPlugin.torusWalletInstance.isInitialized) {
        await tools.loadWeb3("web3auth");
      }
      // tools.web3auth.
      // tools.torusPlugin.torusWalletInstance.hideTorusButton();
      // tools.torusWalletAdapter.torusInstance.showWallet();
      tools.torusPlugin.torusWalletInstance.showWallet();
    },
  },
  computed: mapState({
    user: (state) => state.user.profile ?? {},
    loggedIn: (state) => state.user.profile != null,
  }),
};
</script>

<style scoped>
.v-text-field--outlined>>>fieldset {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.search-field {
  max-width: 300px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 8px;
}
</style>
