<template>
  <v-app id="app">
    <vue-confirm-dialog></vue-confirm-dialog>

    <router-view></router-view>

    <v-snackbar
      v-model="show"
      :multi-line="true"
      :right="true"
      :top="true"
      timeout="6000"
      :color="$store.state.snackbar.variant"
    >
      {{ $store.state.snackbar.message }}
      <v-btn dark text @click="$store.commit('updateSnackBar', { show: false })">
        close</v-btn
      >
    </v-snackbar>
  </v-app>
</template>

<script>
import * as tools from "./store/tools";

export default {
  name: "App",
  // components: {
  //   // LottieAnimation
  // },
  created() {
    console.log(window.crossOriginIsolated, "crossOriginIsolated");
    // set default theme from user settings if available
    this.$vuetify.theme.dark = this.$store.state.settings.darkMode;
    tools.convertToCurrency("USD", "ETH").then((response) => {
      if (response) {
        this.$store.commit("updateSettings", {
          currentETHUSDPrice: response,
        });
      }
    });
    tools.convertToCurrency("USD", "BNB").then((response) => {
      if (response) {
        this.$store.commit("updateSettings", {
          currentBNBUSDPrice: response,
        });
      }
    });
  },
  data() {
    return {
      background: this.$store.state.settings.darkMode ? "#000000" : "#FFFFFF",
    };
  },
  computed: {
    show: {
      // getter
      get: function () {
        return this.$store.state.snackbar.show;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("updateSnackBar", {
          show: newValue,
        });
      },
    },
  },
};
</script>

<style>
.v-label,
.v-text-field input,
textarea {
  font-size: 13px !important;
}

.somemain {
  background-color: #ce45f70d !important;
}

.mdi.mdi-18 {
  font-size: 32px !important;
}

[lazy-src]::before {
  height: 20px;
  content: "";
  display: block;
  padding-top: 56.25%;
}

.primary-gradient {
  background: linear-gradient(
    300deg,
    rgba(24, 113, 255, 1) 10%,
    rgba(214, 12, 248, 1) 65%
  ) !important;
  color: linear-gradient(
    300deg,
    rgba(24, 113, 255, 1) 10%,
    rgba(214, 12, 248, 1) 65%
  ) !important;
}

@media (min-width: 768px) {
  .v-label,
  .v-text-field input,
  textarea {
    font-size: 14px !important;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1200px !important;
  }
}
</style>
