import Vue from 'vue'
import { store } from "./store"
import App from './App.vue'
// router setup
import router from './routes/router'
import VueMasonry from 'vue-masonry-css';
import DatetimePicker from 'vuetify-datetime-picker';
import GSignInButton from 'vue-google-signin-button';
import FBSignInButton from 'vue-facebook-signin-button';
import * as network from "./network.js";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueObserveVisibility from 'vue-observe-visibility'
import './index.css';
import vuetify from './plugins/vuetify'



Vue.config.productionTip = false

Vue.use(VueMasonry);
Vue.use(DatetimePicker);
Vue.use(GSignInButton)
Vue.use(FBSignInButton)
Vue.use(VueObserveVisibility)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

new Vue({
  vuetify,
  store: store,
  router: router,
  render: h => h(App)
}).$mount('#app')

const token = store.getters.userToken;
// console.log(token);
if (token) {
  network.setAxiosHeaders({"Authorization": "Token "+token, "Content-Type": 'application/json'})
}


Array.prototype.shuffle = function() {
  var i = this.length, j, temp;
  if ( i == 0 ) return this;
  while ( --i ) {
     j = Math.floor( Math.random() * ( i + 1 ) );
     temp = this[i];
     this[i] = this[j];
     this[j] = temp;
  }
  return this;
}
