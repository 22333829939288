<template>
    <v-menu close-on-click offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-5" :x-small="$vuetify.breakpoint.smAndUp == false" :small="$vuetify.breakpoint.smAndUp" icon
                v-bind="attrs" v-on="on">

                <slot name="activator" :on="on" :attrs="attrs">
                    <v-icon :small="$vuetify.breakpoint.smAndUp == false">mdi-wallet</v-icon>
                </slot>
            </v-btn>
        </template>

        <v-list>
            <h5 class="mb-1 px-4">Connect to a Wallet</h5>
            <v-list-item dense @click="tools.loadWeb3(option.name)" v-for="option in paymentOptions">
                <v-list-item-avatar rounded size="24px">
                    <v-img width="16px" contain :src="$vuetify.theme.dark
                        ? option.darkIcon
                        : option.icon
                        "></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ option.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import * as tools from "../store/tools";

export default {
    name: "WalletOptionsMenu",
    data() {
        return {
            tools,
            paymentOptions: [
                {
                    name: "web3auth",
                    title: "Web3 Auth",
                    icon: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
                    darkIcon: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
                },
                {
                    name: "web3modal",
                    title: "Wallet Connect",
                    icon: "https://walletconnect.com/_next/static/media/brand_logo_blue.60e0f59b.svg",
                    darkIcon: "https://walletconnect.com/_next/static/media/brand_logo_blue.60e0f59b.svg"
                },
                {
                    name: "metamask",
                    title: "MetaMask",
                    icon: "https://github.com/MetaMask/brand-resources/raw/master/SVG/SVG_MetaMask_Icon_Color.svg",
                    darkIcon: "https://github.com/MetaMask/brand-resources/raw/master/SVG/SVG_MetaMask_Icon_Color.svg"
                }

            ],
        }
    }
}
</script>