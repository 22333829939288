
<template>
  <v-dialog
    v-model="status"
    @click:outside="close"
    :scrollable="false"
    max-width="850px"
  >
    <v-card>
      <v-container
        fluid
        class="dialog-container ma-0"
        style="overflow: hidden;"
      >
        <v-row no-gutters>
          <v-col xs="12" sm="7" md="8" lg="8" xl="8" class="pa-0 ma-0">
            <v-container class="pa-0 ma-0 flex-grow-0">
              <v-img 
                :src="
                  post.media_files[0].url
                "
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.8)"
                class="white--text align-end fixed"
                min-height="550px"
                max-height="550px"
                cover
              >
                <v-card flat color="transparent" class="py-2">
                  <v-card-title
                    v-text="post.title"
                    class="white--text py-0 px-4"
                  ></v-card-title>
                  <v-card-actions class="px-3 py-0">
                    <v-btn icon @click="sendLike" large>
                      <v-icon v-if="post.liked" color="red">mdi-heart</v-icon>
                      <v-icon v-else color="#D7E0EB">mdi-heart</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="
                        $router.push({
                          name: 'post-details',
                          params: {
                            id: post.id,
                            title: post.title,
                            data: post,
                          },
                        })
                      "
                    >
                      <v-icon color="#D7E0EB">mdi-chat</v-icon>
                    </v-btn>
                    <ShareButton :post="post" />

                    <v-spacer></v-spacer>
                    <v-icon>mdi-ethereum</v-icon>
                    <span class="text-caption">
                      {{ Intl.NumberFormat("en-US", {}).format(post.price) }}
                      <b>ETH</b>
                    </span>
                  </v-card-actions>
                </v-card>
              </v-img>
            </v-container>
          </v-col>

          <v-col
            style="background-color: #ce45f70d"
            xs="12"
            sm="5"
            md="4"
            lg="4"
            xl="4"
          >
            <v-container
              fluid
              class="flex-column"
              style="flex-wrap: nowrap; max-height: 582px"
            >
              <v-list-item two-line class="flex-shrink-0 flex-grow-0 mb-3">
                <v-list-item-avatar
                  color="primary"
                  @click="goToProfile(post.owner)"
                >
                  <p
                    class="mb-0 white--text heading"
                    v-if="post.owner.profile.profile_pic == ''"
                  >
                    {{ post.owner.username[0].toUpperCase() }}
                  </p>
                  <v-img 
                    sizes="120"
                    contain
                    v-else
                    :src="post.owner.profile.profile_pic"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title @click="goToProfile(post.owner)">{{
                    post.owner.username
                  }}
                <v-icon v-if="post.owner.profile.is_verified" class="ml-2" color="primary">mdi-check-decagram</v-icon>
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{ moment(post.created_time).fromNow() }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-row align="center" justify="end">
                  <v-btn icon @click="close" class="d-md-none">
                    <v-icon class="mr-1"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-list-item>
              <div class="flex-grow-1">
                <v-tabs v-model="tab">
                  <v-tab>Comments</v-tab>
                  <v-tab>Bids</v-tab>
                  <v-tabs-slider color="#CE45F7"></v-tabs-slider>
                </v-tabs>
                <v-tabs-items
                  v-model="tab"
                  class="mt-3"
                  style="background-color: #ce45f70"
                >
                  <v-tab-item>
                    <div class="d-flex flex-column">
                      <div class="flex-grow-1">
                        <v-list class="overflow-y-auto" height="350px">
                          <comment
                            :comment="comment"
                            v-for="comment in comments"
                            :key="comment.id"
                          />
                        </v-list>
                      </div>
                    </div>
                  </v-tab-item>

                  <v-tab-item>
                    <div class="d-flex flex-column">
                      <div class="flex-grow-1">
                        <v-list class="overflow-y-auto" height="350px">
                          <bid :bid="bid" v-for="bid in bids" :key="bid.id" />
                        </v-list>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Bid from "./Bid.vue";
import Comment from "./Comment.vue";
import ShareButton from "../ShareButton";
import axios from "axios";
import { BACKEND_URL,IPFS_MEDIA_URL } from "../../store/config";
import moment from "moment";

export default {
  name: "PostView",
  components: {
    Comment,
    Bid,
    ShareButton,
  },
  created() {
    // get comment list
    axios
      .get(BACKEND_URL + `/nft/comment?collectable=${this.post.id}`)
      .then((response) => {
        var resp = response.data;
        console.log(resp);
        this.comments = resp;
      })
      .catch((error) => {
        console.log(error);
      });
    // get bid list
    axios
      .get(BACKEND_URL + `/nft/bid?collectable=${this.post.id}`)
      .then((response) => {
        var resp = response.data;
        console.log(resp);
        this.bids = resp;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  data() {
    return {
      status: this.open,
      tab: "comments",
      comment_text: "",
      comment_loading: false,
      comments: [],
      bids: [],
      IPFS_MEDIA_URL: IPFS_MEDIA_URL

    };
  },
  props: {
    open: Boolean,
    post: Object,
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    goToProfile(user) {
      this.$router.push({
        name: "user-profile",
        params: { id: user.id, username: user.username, userAccount: user },
      });
    },
    sendLike() {
      if (this.$store.getters.getProfile == null) {
        this.$store.commit("updateSnackBar", {
          show: true,
          message: "You need to be logged in",
          variant: "error",
        });
        return;
      }

      this.post.liked = !(this.post.liked ?? false);
      // send like update request
      this.post = { ...this.post };

      axios
        .post(BACKEND_URL + "/nft/like/", {
          user: this.$store.getters.getProfile.id,
          collectable: this.post.id,
        })
        .then((response) => {
          var resp = response.data;
          console.log(resp);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    moment,
  },
};
</script>
