var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-lazy',{attrs:{"options":{
    threshold: 0.5,
  },"height":"100%","max-height":"350px","transition":"fade-transition"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',{attrs:{"flat":"","rounded":"","height":"100%"},on:{"click":function($event){$event.stopPropagation();return (() => {

                _vm.$router.push({
                  name: 'post-details',
                  params: { id: _vm.post.id, title: _vm.post.title, data: _vm.post },
                });
              }).apply(null, arguments)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-sheet',{attrs:{"height":"100%","width":"100%"}},[(_vm.mediaType == 'image')?_c('v-img',{staticClass:"rounded",attrs:{"src":_vm.post.media_files[0].url,"lazy-src":require("../../assets/lazy-src.gif"),"gradient":"to bottom, rgba(0,0,0,0), rgba(0,0,0,.4)","aspect-ratio":1,"height":"100%"},on:{"click":function($event){$event.stopPropagation();return (() => {
                // if (post.media_files.length > 0) {
                //   return;
                // }

                _vm.$router.push({
                  name: 'post-details',
                  params: { id: _vm.post.id, title: _vm.post.title, data: _vm.post },
                });
              }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-sheet',{staticClass:"pa-5",attrs:{"color":"grey","width":"100%","height":"100%"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-img',{attrs:{"src":require("../../assets/lazy-src.gif"),"width":"50px","height":"50px","contain":""}})],1)],1)],1)],1)]},proxy:true}],null,true)},[_c('v-scale-transition',{attrs:{"origin":"center center 0"}},[(hover &&  _vm.$vuetify.breakpoint.smAndUp)?_c('v-overlay',{attrs:{"absolute":"","color":"#000000"}},[_c('v-container',[_c('div',{staticClass:"d-flex px-2 justify-between"},[_c('span',{staticClass:"text--white"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-heart")]),_c('span',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v(_vm._s(_vm.post.likes))])],1),_c('span',{staticClass:"mx-3"}),_c('span',{staticClass:"text--white"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-comment")]),_c('span',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v(_vm._s(_vm.post.comments))])],1)])])],1):_vm._e()],1)],1):_vm._e(),(_vm.mediaType == 'video')?_c('v-responsive',{staticClass:"rounded absolute",attrs:{"width":"100%","height":"100%"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'post-details',
                params: { id: _vm.post.id, title: _vm.post.title, data: _vm.post },
              })}}},[_c('v-scale-transition',{attrs:{"origin":"center center 0"}},[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#000000"}},[_c('v-container',[_c('div',{staticClass:"d-flex px-5 justify-between"},[_c('span',{staticClass:"text--white mr-7"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-heart")]),_c('span',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v(_vm._s(_vm.post.likes))])],1),_c('span',{staticClass:"text--white"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-comment")]),_c('span',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v(_vm._s(_vm.post.comments))])],1)])])],1):_vm._e()],1),_c('video',{staticClass:"grey rounded",class:{ 'on-hover': hover },staticStyle:{"width":"100%","object-fit":"cover !important"},attrs:{"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","muted":"","loop":"","autoplay":"","playsinline":"","height":"100%"},domProps:{"muted":true}},[_c('v-scale-transition',{attrs:{"origin":"center center 0"}},[(hover &&  _vm.$vuetify.breakpoint.smAndUp)?_c('v-overlay',{attrs:{"absolute":"","color":"#000000","z-index":"0"}},[_c('v-container',[_c('div',{staticClass:"d-flex px-5 justify-between"},[_c('span',{staticClass:"text--white mr-7"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-heart")]),_c('span',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v(_vm._s(_vm.post.likes))])],1),_c('span',{staticClass:"text--white"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-comment")]),_c('span',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v("...")])],1)])])],1):_vm._e()],1),_c('source',{attrs:{"src":_vm.post.media_files[0].url + '#t=0,10',"type":"video/mp4"}})],1)],1):_vm._e()],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }