<template>
    <svg class="custom-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve">
        <g id="_x31_1">
            <path d="M128,37.94c0-6.248-6.785-11.656-19.63-15.643c-11.888-3.682-27.633-5.712-44.363-5.712
            s-32.489,2.03-44.363,5.712C6.799,26.284,0,31.692,0,37.94V93.38h0.015C0,93.452,0,93.525,0,93.597
            c0,7.075,8.916,13.164,25.11,17.151l2.697,0.667V80.042c9.772-2.247,22.138-3.682,36.201-3.682c14.077,0,26.444,1.435,36.201,3.682
            v31.373l2.697-0.667C119.084,106.761,128,100.672,128,93.597c0-0.072,0-0.145-0.015-0.217H128V37.94z M23.457,105.819
            c-11.859-3.276-19.108-7.872-19.108-12.222c0-0.131,0-0.275,0.015-0.406c0.449-4.219,7.611-8.626,19.093-11.816V105.819z
            M104.557,105.819V81.375c11.482,3.19,18.644,7.597,19.079,11.816c0.015,0.131,0.015,0.275,0.015,0.406
            C123.651,97.946,116.416,102.542,104.557,105.819z M123.651,42.478c-4.451,3.16-12.12,6.046-21.747,8.177l-1.696,0.377v21.862
            h4.349V54.511c7.945-1.87,14.425-4.219,19.093-6.901v37.636c-3.291-2.856-8.423-5.393-15.281-7.51
            C96.482,74.04,80.738,72.01,64.007,72.01s-32.489,2.03-44.363,5.727c-6.857,2.117-12.004,4.654-15.295,7.51V47.668
            c4.697,2.653,11.207,5.002,19.108,6.857v18.369h4.349V51.032l-1.711-0.377c-9.51-2.088-17.238-4.973-21.747-8.09V37.94
            c0-8.046,24.501-17.006,59.658-17.006s59.643,8.96,59.643,17.006V42.478z M56.673,36.619h6.137l-9.637,28.266h-5.58l-9.559-28.266
            h6.309l6.137,21.458L56.673,36.619z M84.443,37.347c1.041,0.447,1.923,1.106,2.646,1.975c0.599,0.716,1.072,1.509,1.421,2.379
            s0.524,1.86,0.524,2.972c0,1.344-0.339,2.663-1.017,3.961c-0.677,1.297-1.797,2.214-3.356,2.751
            c1.304,0.525,2.228,1.27,2.771,2.234c0.543,0.966,0.815,2.439,0.815,4.42v1.899c0,1.293,0.052,2.168,0.156,2.628
            c0.156,0.729,0.52,1.266,1.091,1.611v0.709h-6.52c-0.179-0.626-0.307-1.131-0.383-1.515c-0.154-0.793-0.237-1.604-0.25-2.435
            l-0.038-2.628c-0.024-1.802-0.336-3.004-0.934-3.605c-0.598-0.602-1.72-0.902-3.365-0.902h-5.769v11.084h-5.772V36.619h13.52
            C81.915,36.657,83.403,36.899,84.443,37.347z M72.236,41.528v7.594h6.356c1.263,0,2.21-0.153,2.841-0.46
            c1.117-0.537,1.676-1.598,1.676-3.184c0-1.713-0.541-2.863-1.621-3.452c-0.607-0.331-1.518-0.498-2.732-0.498H72.236z"/>
        </g>
        <g id="Layer_1"></g>
    </svg>
</template>

<script>
export default {};
</script>
<style lang="scss">
.custom-icon {
  fill: currentColor
}
</style>
