import { USER_REQUEST, USER_ERROR, USER_SUCCESS, WALLET_UPDATE, USER_UPDATE } from "../actions/user";
import { AUTH_LOGOUT } from "../actions/auth";
import { BACKEND_URL } from "../config";
import axios from "axios";
import * as network from "../../network.js";

const state = {
  status: "", profile: null,
  walletData: null,
};

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    axios({ baseURL: BACKEND_URL, url: "/users/", method: 'GET' })
      .then(resp => {
        commit(USER_SUCCESS, resp);
        console.log(resp);
      })
      .catch((error) => {
        commit(USER_ERROR);
        console.log(error);
        console.log("Wahala");
        console.log(error)
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });
  },
  [WALLET_UPDATE]: ({ commit }, data) => {
    console.log('action', data)
    commit(WALLET_UPDATE, data)
  },

  [USER_UPDATE]: ({ commit }, profile) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit(USER_REQUEST);
      axios({ baseURL: BACKEND_URL, url: "/accounts/profile/edit/", method: 'POST', data: profile })
        .then(resp => {
          commit(USER_SUCCESS, resp);
          console.log(resp);
          resolve(resp);
        })
        .catch((error) => {
          console.log("Wahala");
          console.log(error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    console.log(resp);
    state.status = "success";
    console.log('Committing ' + resp.data.profile.first_name);
    console.log(resp.data);
    state.profile = resp.data;
    localStorage.setItem('user-profile', JSON.stringify(resp.data)) // store profile in local storage
    if ( resp.data && resp.data.token ) {
      localStorage.setItem('access-token', JSON.stringify(resp.data.token)) // store the token in localstorage
      network.setAxiosHeaders({ "Authorization": "Token " + resp.data.token, "Content-Type": 'application/json' })
    }
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = null;
    state.token = null;
    localStorage.removeItem('access-token')
    localStorage.removeItem('user-profile')
    network.setAxiosHeaders({ "Content-Type": 'application/json' })
    sessionStorage.clear();
  },
  [USER_ERROR]: state => {
    state.profile = null;
    state.token = null;
    localStorage.removeItem('access-token')
    localStorage.removeItem('user-profile')
    network.setAxiosHeaders({ "Content-Type": 'application/json' })
    sessionStorage.clear();
  },
  [WALLET_UPDATE]: (state, data) => {
    console.log('commit', data)
    state.walletData = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};