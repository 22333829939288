<template>
  <v-container fluid class="px-0">
    <div class="flex flex-column align-items-center">
      <div>
        <h4 class="subtitle-1 font-weight-medium mb-2">Suggested Users</h4>
        <div v-for="(user, i) in users" :key="i">

          <v-list-item dense class="px-0" v-if="!user.following">
            <v-list-item-avatar @click.stop="goToUserProfile(user)" :size="40" class="avatar mr-3">
              <div class="pa-1 rounded-circle" style="width: 100%; height: 100%">
                <!-- ="anonymous" -->
                <v-img v-if="user.profile.profile_pic" class="rounded-circle" :src="user.profile.profile_pic"
                  width="100%"></v-img>
                <v-img v-else class="rounded-circle pa-1"
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                  width="100%"></v-img>
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <!-- <div class="d-flex justify-space-between align-center"> -->
                <a class=" subtitle-2" @click.stop="goToUserProfile(user)"><span>{{ user.username ?
                  user.username : user.profile.first_name }}</span></a>
                <!-- </div> -->
              </v-list-item-title>
              <v-list-item-subtitle v-if="user.profile.followers">
                <p class="caption text-wrap mb-0">
                  Followed by {{ user.profile.followers }} others
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>

              <v-btn :loading="loading[i]"  text small v-if="$store.getters.getProfile &&
                $store.getters.getProfile.id != user.id
                " @click="() => followUser(user, i)" class="caption font-weight-medium primary--text">
                <div v-if="!user.following">+ Follow</div>
                <div v-else >Unfollow</div>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </div>

        <v-divider></v-divider>
      </div>
    </div>
  </v-container>
</template>


<script>
import * as network from "../../network.js";

export default {
  data() {
    return {
      users: [],
      loading: [],
    };
  },
  created() {
    /// get suggestion
    network
      .sendCachedRequest("/accounts/suggested_accounts", (response) => {
        var resp = response.data;
        console.log(resp);
        this.users = resp;
        this.loading = this.users.map(() => false)
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    goToUserProfile(user) {
      this.$router.push({
        name: 'user-profile',
        params: {
          id: user.id,
          username: user.username,
          userAccount: null// user,
        },
      })
    },
    followUser(user, index) {
      this.loading[index] = true
      this.loading = this.loading
      console.log(this.loading, index)
      if (this.$store.getters.getProfile == null) {
        this.$store.commit("updateSnackBar", {
          show: true,
          message: "You need to be logged in",
          variant: "error",
        });
        return;
      }

      network.axios
        .post("/accounts/follow/", {
          user: this.$store.getters.getProfile.id,
          following: user.id,
        })
        .then((response) => {
          var resp = response.data;
          this.loading[index] = false;
          this.loading = this.loading
          console.log(this.loading)

          console.log(resp);
          if (resp.status == "unfollowed") {
            user.following = false;
          } else {
            user.following = true;
          }

          this.users = { ...this.users };
        })
        .catch((error) => {
          this.loading[index] = false;
          this.loading = this.loading
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.avatar {
  border: 1px solid #ce45f796;
}
</style>
