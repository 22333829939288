import axios from "axios";
import { BACKEND_URL, S3_URL } from "../store/config";

const Web3 = require("web3");
import Torus from "@toruslabs/torus-embed";
import { BLOCKCHAIN_NETWORK } from "./config";
// import Portis from '@portis/web3';
var mime = require('mime-types')
import * as network from "../network.js";
import { loadBlockchain, arvContracts } from "../views/Post/nft.js";
import Artemis from '../abis/Artemis.json'
import { store } from "../store"
import { Web3Auth } from "@web3auth/modal";
// import { OPENLOGIN_NETWORK_TYPE } from "@web3auth/base";
import { TorusWalletAdapter } from "@web3auth/torus-evm-adapter";
import { TorusWalletConnectorPlugin } from "@web3auth/torus-wallet-connector-plugin";

import networks from '../contracts/networks.json'

// select default chain
if (!window.actvieWeb3Chain) {
    console.log("Setting Default Chain")
    window.actvieWeb3Chain = networks[1];
}

export const web3auth = new Web3Auth({
    clientId: "BBi71OAu7QSm1vyKg80fqUslfC2RQebwWWWbOphBqxBwsbVJiXWgQxzLOKXDN6YpybIYky6m3DxaYPUe1K9phAk", // Get your Client ID from Web3Auth Dashboard
    chainConfig:
    {
        chainNamespace: "eip155",
        chainId: Web3.utils.toHex(window.actvieWeb3Chain.id), //"0xaa36a7", // "0x61", // hex of 97
        rpcTarget: window.actvieWeb3Chain.rpc, // "https://sepolia.infura.io/v3/a116b5fb562d4d37adfd7ead1b652f4d", //"https://rpc.ankr.com/bsc_testnet_chapel",
        displayName: window.actvieWeb3Chain.label, // "Sepolia Testnet", //"Binance SmartChain Testnet",
        // blockExplorer: "https://testnet.bscscan.com",
        ticker: window.actvieWeb3Chain.currencies[0].currency, // "BNB",
        tickerName: window.actvieWeb3Chain.currencies[0].currency // "BNB",
    },
    web3AuthNetwork: 'mainnet',
    enableLogging: true,
});


export const torusWalletAdapter = new TorusWalletAdapter({
    initParams: {
        // type WhiteLabelParams
        whiteLabel: {
            theme: {
                isDark: true,
                colors: { torusBrand1: "#FFA500" },
            },
            logoDark: "https://images.web3auth.io/web3auth-logo-w.svg",
            logoLight: "https://images.web3auth.io/web3auth-logo-w-light.svg",
            topupHide: false,
            featuredBillboardHide: true,
            disclaimerHide: true,
            defaultLanguage: "en",
        },
    },
});

export const torusPlugin = new TorusWalletConnectorPlugin({
    torusWalletOpts: {
        buttonPosition: "bottom-right"
    },
    walletInitOptions: {
        whiteLabel: {
            theme: { isDark: true, colors: { primary: "#00a8ff" } },
            logoDark: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
            logoLight: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
        },
        useWalletConnect: true,
        enableLogging: true,
    },
});

import { EthereumClient, w3mConnectors, w3mProvider, } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { configureChains, createConfig, getAccount } from '@wagmi/core'
import { mainnet } from '@wagmi/core/chains'


import { providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";

export const walletConnectProvider = new WalletConnectProvider({
    infuraId: window.actvieWeb3Chain.rpc.split("v3/")[1],
});


const chains = [mainnet]
const projectId = 'b698146e696899ae602ffed05829bb77'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)
const web3modal = new Web3Modal({ projectId }, ethereumClient)


web3auth.configureAdapter(torusWalletAdapter);
web3auth.initModal();
web3auth.addPlugin(torusPlugin);

export const PLATFORM_FEE = 1.5

export function getFileType(file) {

    if (file.type)
        return fileType(file.type)
    return fileType(mime.lookup(file))
}

export function fileType(type) {
    if (type.match('image.*'))
        return 'image';

    if (type.match('video.*'))
        return 'video';

    if (type.match('audio.*'))
        return 'audio';

    // etc...  
    return 'other';
}


export async function convertToCurrency(to, from) {
    try {
        // fetch data from a url endpoint
        const response = await axios
            .get(BACKEND_URL + `/nft/extras/spot_price?to=${to}&from=${from}`)
        const data = await response.data
        return data['amount']
    } catch (error) {
        console.log(error)
        return null
    }
}



export async function loadWeb3(provider = "ethereum") {
    // if (provider == 'portis') {
    //     const portis = new Portis('YOUR_DAPP_ID', 'mainnet');
    //     window.web3 = new Web3(portis.provider);
    // }

    console.log(provider, "Provider")

    // if (provider == 'torus') {
    //     const torus = new Torus({
    //         buttonPosition: "top-right", // default: 'bottom-left'
    //     });
    //     await torus.init({
    //         // buildEnv: "staging", // uses staging.tor.us
    //         network: {
    //             host: BLOCKCHAIN_NETWORK, // 'mainnet'
    //         },
    //     });
    //     await torus.login(
    //     );
    //     window.web3 = new Web3(torus.provider);
    // }

    if (provider == 'ethereum' && window.ethereum) {
        try {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
        }
        catch (err) {
            console.log(err)
            return
        }
    }

    if (provider == 'web3modal') {

        await new Promise((resolve, reject) => {
            web3modal.subscribeModal(async (state) => {
                let account = getAccount()

                if (!account.address || !account.connector) {
                    console.log("web3 account not ready");
                    if (!state.open) {
                        reject(null);
                    }
                    return
                };
                var web3Provider = await account.connector.getProvider();
                window.web3 = new Web3(web3Provider);
                resolve()
                // window.web3.currentProvider.request({
                //     method: 'wallet_switchEthereumChain',
                //     params: [{ chainId: Web3.utils.toHex(97) }],
                // });
            });
            web3modal.openModal();
        });

    }

    if (provider == 'web3auth' || !window.web3) {
        try {
            console.log("Coneecting to web3auth");
            await web3auth.connect()
            Object.keys(networks).forEach((id) => {
                var chain = networks[id];
                console.log(chain);
                console.log(web3auth.status);
                web3auth.addChain({
                    chainNamespace: "eip155",
                    chainId: Web3.utils.toHex(chain.id),
                    rpcTarget: chain.rpc,
                    displayName: chain.label,
                    // blockExplorer: "https://testnet.bscscan.com",
                    ticker: chain.currencies[0].currency,
                    tickerName: chain.currencies[0].currency
                });
            });

            window.web3 = new Web3(web3auth.provider)
            console.log("Cone2ected to web3auth");
        }
        catch (err) {
            console.log("Error with web3auth ", err)
            return
        }
    }

    if (!window.web3) {
        window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
        return
    }

    var reload = async () => {
        // var data = await loadBlockchain(provider);
        // console.log("tried", data);
        // if (data) {
        //     store.commit("updateWallet", data);
        // }
    }

    window.web3.currentProvider.on("chainChanged", (resp) => {
        var chainId = parseInt(resp, 16);
        console.log("web3 Chain changed", chainId);
        window.actvieWeb3Chain = networks[chainId];
        reload();
    });

    window.web3.currentProvider.on("accountsChanged", (accounts) => {
        console.log("web3 Accounts Changed", accounts);
        reload();
    });


}

export async function getWalletSignature(token_type, onTokenSignSuccess, onTokenRequestFail) {

    // 1. Retrieve arbitrary login token from server
    // 2. Sign it using web3
    // 3. Send signed message & your eth address to server
    // 4. If server validates that you signature is valid
    // 4.1 The user with an according eth address is found - you are logged in
    // 4.2 The user with an according eth address is NOT found - you are redirected to signup page

    await loadWeb3()
    const web3 = window.web3;

    if (!web3) {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
        return
    }
    console.log("getWalletSignature", token_type)
    var address;
    try {
        address = (await web3.eth.getAccounts())[0]; // get user wallet address
    } catch (error) {
        console.log("Error getAccounts", error, address);
        return
    }
    console.log("Address: " + address);

    var url = BACKEND_URL + '/accounts/get_auth_token/'
    axios.post(url, {
        wallet_address: address,
        token_type: token_type
    })
        .then(function (response) {
            console.log(response);

            var token = response.data['data']

            console.log("Token: " + token);
            var msg = web3.utils.toHex(token);

            web3.eth.personal.sign(msg, address, function (err, result) {
                if (err) {
                    if (typeof onTokenRequestFail == 'function') {
                        onTokenRequestFail(err);
                    }
                    console.log("Failed signing message \n" + msg + "\n - " + err);
                } else {
                    // console.log("Signed message: " + result);
                    if (typeof onTokenSignSuccess == 'function') {
                        onTokenSignSuccess(result, address);
                    }
                }
            });


        })
        .catch(function (error) {
            // We reached our target server, but it returned an error
            console.log("Autologin failed - request status " + error);
            if (typeof onTokenRequestFail == 'function') {
                onTokenRequestFail(error);
            }
        });

}

export var S3Upload = {
    init(file, fileName, fileType, onSuccess, onFailure) {
        var ext = fileName.split('.').reverse()[0]
        var name = `${Date.now()}-${fileName}`;
        name = stringToSlug(name)
        name += '.' + ext
        console.log(name, fileType)
        network.axios.get(`/aws/sign_s3/?file_name=${name}&file_type=${fileType}`).then((response) => {
            console.log(response);
            this.uploadFile(file, response, name, onSuccess, onFailure)
        }).catch((error) => {
            onFailure(error);
        })
    },
    uploadFile(file, response, fileName, onSuccess, onFailure) {
        var data = response.data.data.fields
        data.file = file
        var body = new FormData();
        Object.keys(data).forEach((key) => {
            body.append(key, data[key])
        })
        var aws = axios.create(
            {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: " " }
            }
        )
        aws.post(response.data.data.url, body).then((response) => {
            onSuccess(response, S3_URL + fileName);
            console.log(response);
        }).catch((error) => {
            onFailure(error);
        })

    }


}


export function stringToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}


export function isSafari() {
    var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    return is_safari;
}

export async function downloadFile(url) {
    try {
        var response = await axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
        })
        //   window.URL.createObjectURL
        const blob = new Blob([response.data]);
        return blob;
    }

    catch (err) {
        // Handle Error Here
        console.error(err);
    }

}

export function secondsToHHMMSS(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
}

export async function checkPayment(currency, amount, receiver, from) {
    let data = {
        from: from,
    };

    if (currency.address) { // ignore currency without custom address, that is base currency
        try {

            let erc20 = new web3.eth.Contract(Artemis.abi, currency.address);
            await erc20.methods
                .approve(
                    receiver,
                    amount
                )
                .send({
                    from: from,
                });
        } catch (error) {
            console.log("Error approving payment", error);
        }

    } else {
        data.value = amount;
    }
    return data;
}

export async function approveForSale(token_type, token_id, from) {
    let approveData;
    let isApprovedForAll;
    if (token_type == "single") {
        console.log('sdfse');

        approveData = await arvContracts.erc721.methods.approve(arvContracts.erc721Escrow.address, token_id)
            .send({ from: from })
        console.log(approveData)
    }
    else {
        isApprovedForAll = await arvContracts.erc1155.methods.isApprovedForAll(from, arvContracts.erc1155Escrow.address).call();
        console.log('Escrow isApprovedForAll', isApprovedForAll)
        if (!isApprovedForAll) {
            approveData = await arvContracts.erc1155.methods.setApprovalForAll(arvContracts.erc1155Escrow.address, true)
                .send({ from: from })
            console.log(approveData)
        }
    }
}
