import {
  AUTH_REQUEST,
  AUTH_SIGNUP,
  AUTH_SOCIAL_SIGNUP,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_SUCCESS } from "../actions/user";
import { BACKEND_URL } from "../config";
import axios from "axios";
import * as network from "../../network.js";


const state = {
  token: "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  userToken: state => state.token,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit(AUTH_REQUEST)
      axios({ baseURL: BACKEND_URL, url: '/accounts/login/', data: user, method: 'POST' })
        .then(resp => {
          console.log(resp);
          const token = resp.data.token
          commit(AUTH_SUCCESS, token)
          // you have your token, now log in your user :)
          commit(USER_SUCCESS, resp);
          resolve(resp)
        })
        .catch(err => {
          console.log("Error");
          commit(AUTH_ERROR, err)
          reject(err)
        })
    })
  },
  [AUTH_SIGNUP]: ({ commit }, user) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit(AUTH_REQUEST)
      axios({ baseURL: BACKEND_URL, url: '/accounts/signup/create_account/', data: user, method: 'POST' })
        .then(resp => {
          console.log(resp);
          commit(AUTH_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          console.log("Error");
          commit(AUTH_ERROR, err)
          reject(err)
        })
    })
  },
  [AUTH_SOCIAL_SIGNUP]: ({ commit }, user) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit(AUTH_REQUEST)
      axios({ baseURL: BACKEND_URL, url: '/accounts/signup/create_account/', data: user, method: 'POST' })
        .then(resp => {
          console.log(resp);
          const token = resp.data.access;
          commit(AUTH_SUCCESS, token);
          commit(USER_SUCCESS, resp); resolve(resp)
        })
        .catch(err => {
          console.log("Error");
          commit(AUTH_ERROR, err)
          localStorage.removeItem('access-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT)
      console.log("Logging Out!!");
      resolve()
    })
  },
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, token) => {
    console.log(state, 'state from auth')
    state.status = 'success'
    state.token = token
    network.setAxiosHeaders({ "Authorization": "Token " + token, "Content-Type": 'application/json' })
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = null
    state.profile = null;
    localStorage.removeItem('access-token')
    localStorage.removeItem('user-profile')
    network.setAxiosHeaders({ "Content-Type": 'application/json' })

    sessionStorage.clear();
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    localStorage.removeItem('access-token') // if the request fails, remove any possible user token if possible
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};

