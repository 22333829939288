import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import VueSocialSharing from 'vue-social-sharing'
import VRIcon from "../components/vrIconComponent.vue";

// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
});

Vue.use(VuetifyToast, {
  x: 'center', // default
  y: 'bottom', // default
  color: 'info', // default
  icon: 'mdi-info',
  timeout: 3000, // default
  dismissable: true, // default
  autoHeight: false, // default
  multiLine: false, // default
  vertical: false, // default
  shorts: {
    primary: {
      color: 'primary',
      icon: "mdi-lightbulb"
    },
    info: {
      color: "info",
      icon: "mdi-lightbulb"
    },
    success: {
      color: "success",
      icon: "mdi-check-circle"
    },
    error: {
      color: "error",
      icon: "mdi-alert-circle"
    },
    warning: {
      color: "warning",
      icon: "mdi-alert"
    }
  },
  property: '$toast' // default
});

const opts = {
  customVariables: ['../../sass/variables.scss'],
  icons: {
    iconfont: 'mdi',
    values: {
      custom: { // name of our custom icon
        component: VRIcon, // our custom component
      },
    },
  },
  treeShake: true,
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: '#fff',
        primary: '#b918f4',
        secondary: '#007af6',
        accent: '#8c9eff',
        error: '#FF6868',
      },
      dark: {
        background: "#000000",
        bg_color: "#000000",
        primary: '#b918f4',
        secondary: '#007af6',
        accent: '#8c9eff',
        error: '#FF6868',
      },
    },
  },
}

let vuetify = new Vuetify(opts);

const simpleIcons = require('simple-icons');

for (const iconSlug in simpleIcons) {
  const icon = simpleIcons.Get(iconSlug);
  vuetify.framework.icons.values[`si-${icon.slug}`] = icon.path
}

Vue.use(VuetifyToast)
Vue.use(VueSocialSharing);

export default vuetify;
