<template>
  <v-autocomplete
    v-model="query"
    :items="results"
    :loading="searching"
    :search-input.sync="search"
    :filter="filterObjects"
    clearable
    hide-details
    hide-selected
    label="Search"
    background-color="#ce45f70D"
    prepend-inner-icon="mdi-magnify"
    single-line
    return-object
    append-icon=""
    solo
    dense
    flat
    :menu-props="{ left: true, offsetY: true }"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-if="search == '' || search == null || search == undefined">
          Search for a
          <strong class="primary--text"> collectable, user or tag</strong>
        </v-list-item-title>
        <v-list-item-title v-else> No search results found. </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <!-- Tag -->
      <v-list-item
        v-if="item.tags.includes(query) && query.startswith('#')"
        @click="goToTag(item.tags.find(query))"
      >
        <v-list-item-avatar
          color="indigo"
          rounded
          class="text-h5 font-weight-light white--text"
          >#</v-list-item-avatar
        >
        <v-list-item-content>
          {{ item.tags.find(query) }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-avatar
          color="indigo"
          rounded
          class="text-h5 font-weight-light white--text"
          @click="goToPost(item)"
        >
          <v-img :src="item.media_files[0].url" v-if=""></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            tag="a"
            class="text-subtitle-1 white--text"
            v-text="item.title"
            @click="goToPost(item)"
          ></v-list-item-title>
          <v-list-item-subtitle class="font-weight-light">
            by
            <a @click="goToUserProfile(item.owner)" class="text--primary">{{
              item.owner.username
            }}</a>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from "debounce";
import axios from "axios";
import { BACKEND_URL } from "../store/config";

export default {
  name: "SearchBar",
  data() {
    return {
      searching: false,
      results: [],
      query: "",
      search: "",
    };
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (!val) {
        return;
      }
      debounce(this.makeSearch, 300)(val);
    },
  },
  methods: {
    goToUserProfile(user) {
      this.$router.push({
        name: "user-profile",
        params: {
          id: user.id,
          username: user.username,
          userAccount: null, // user,
        },
      });
    },
    goToPost(post) {
      this.$router.push({
        name: "post-details",
        params: { id: post.id, title: post.title, data: post },
      });
    },
    goToTag(tag) {
      this.$router.push({
        name: "searchResults",
        params: { query: tag },
      });
    },
    filterObjects(item, queryText, itemText) {
      return (
        item.title.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
        item.owner.username.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
        item.tags.includes(queryText.toLocaleLowerCase())
      );
    },
    async makeSearch(val) {
      if (!val) {
        this.results = [];
        this.query = "";
      }
      // Items have already been requested
      if (this.searching) {
        return;
      }

      this.searching = true;
      // Lazily load input items
      await axios
        .get(BACKEND_URL + "/nft/collectable/?search=0".replace("0", val))
        // .then(res => res.clone().json())
        .then((res) => {
          this.results = res.data.results;
          console.log(this.results);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.searching = false));
    },
  },
};
</script>
