<template>
  <div class="d-flex align-start my-4">
    <div>
      <v-avatar size="50">
        <v-img 
          sizes="50"
          contain
          :src="
            bid.user.profile.profile_pic
              ? bid.user.profile.profile_pic
              : 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
          "
          alt="avatar"
        ></v-img>
      </v-avatar>
    </div>
    <div class="ml-3">
      <p class="subtitle-1 font-weight-bold mb-0">
        {{ bid.user.username }}
      </p>
      <p class="caption grey--text mb-0">
        {{ moment(bid.created_time).format("MMMM Do YYYY, h:mm:ss a") }}
      </p>
      <p class="mt-3 mb-5">
        Placed a bid of
        <span class="font-weight-bold black--text"
          >{{ Intl.NumberFormat("en-US", {}).format(bid.price) }} ETH</span
        >
      </p>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Bid",
  props: {
    bid: Object,
  },
  methods: {
    moment
  }
};
</script>