<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 0.5,
    }"
    height="100%"
    max-height="350px"
    transition="fade-transition"
  >
    <v-card flat rounded height="100%" @click.stop="
                () => {

                  $router.push({
                    name: 'post-details',
                    params: { id: post.id, title: post.title, data: post },
                  });
                }
              ">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-sheet height="100%" width="100%">
            <v-img
              v-if="mediaType == 'image'"
              class="rounded"
              :src="post.media_files[0].url"
              lazy-src="../../assets/lazy-src.gif"
              gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.4)"
              :aspect-ratio="1"
              height="100%"
              @click.stop="
                () => {
                  // if (post.media_files.length > 0) {
                  //   return;
                  // }

                  $router.push({
                    name: 'post-details',
                    params: { id: post.id, title: post.title, data: post },
                  });
                }
              "
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-sheet color="grey" width="100%" height="100%" class="pa-5">
                    <v-container fill-height fluid>
                      <v-row justify="center" align="center">
                        <v-img  src="../../assets/lazy-src.gif" width="50px"
                        height="50px" contain></v-img>
                      </v-row>
                    </v-container>
                  </v-sheet>
                </v-row>
              </template>
              <v-scale-transition origin="center center 0">
                <v-overlay v-if="hover &&  $vuetify.breakpoint.smAndUp" absolute color="#000000">
                  <v-container>
                    <div class="d-flex px-2 justify-between">
                      <span class="text--white"
                        ><v-icon color="white" class="mr-1">mdi-heart</v-icon
                        ><span class="subtitle-2 font-weight-medium">{{
                          post.likes
                        }}</span></span
                      >
                      <span class="mx-3"></span>
                      <span class="text--white"
                        ><v-icon color="white" class="mr-1">mdi-comment</v-icon
                        ><span class="subtitle-2 font-weight-medium">{{
                          post.comments
                        }}</span></span
                      >
                    </div>
                  </v-container>
                </v-overlay>
              </v-scale-transition>
            </v-img>
            <v-responsive
              width="100%"
              height="100%"
              class="rounded absolute"
              v-if="mediaType == 'video'"
              @click.stop="
                $router.push({
                  name: 'post-details',
                  params: { id: post.id, title: post.title, data: post },
                })
              "
            >
              <v-scale-transition origin="center center 0">
                <v-overlay v-if="hover" absolute color="#000000">
                  <v-container>
                    <div class="d-flex px-5 justify-between">
                      <span class="text--white mr-7"
                        ><v-icon color="white" class="mr-1">mdi-heart</v-icon
                        ><span class="subtitle-2 font-weight-medium">{{
                          post.likes
                        }}</span></span
                      >
                      <span class="text--white"
                        ><v-icon color="white" class="mr-1">mdi-comment</v-icon
                        ><span class="subtitle-2 font-weight-medium">{{
                          post.comments
                        }}</span></span
                      >
                    </div>
                  </v-container>
                </v-overlay>
              </v-scale-transition>
              <video
                class="grey rounded"
                :class="{ 'on-hover': hover }"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                style="width:100%; object-fit: cover !important"
                muted
                loop
                autoplay
                playsinline
                height="100%"
              >
                <v-scale-transition origin="center center 0">
                  <v-overlay v-if="hover &&  $vuetify.breakpoint.smAndUp" absolute color="#000000" z-index="0">
                    <v-container>
                      <div class="d-flex px-5 justify-between">
                        <span class="text--white mr-7">
                          <v-icon color="white" class="mr-1">mdi-heart</v-icon>
                          <span class="subtitle-2 font-weight-medium">{{
                            post.likes
                          }}</span></span
                        >
                        <span class="text--white"
                          ><v-icon color="white" class="mr-1"
                            >mdi-comment</v-icon
                          ><span class="subtitle-2 font-weight-medium"
                            >...</span
                          ></span
                        >
                      </div>
                    </v-container>
                  </v-overlay>
                </v-scale-transition>
                <source :src="post.media_files[0].url + '#t=0,10'" type="video/mp4" />
              </video>
            </v-responsive>
          </v-sheet>
        </template>
      </v-hover>
      <!-- <v-card-text class="pa-0">
      <v-container class="py-2 px-0">
        <div class="d-flex justify-space-between align-items-center">
          <p class="subtitle-1 font-weight-medium mb-0">{{ title }}</p>
          <v-chip v-if="forSale == true" pill small class="pa-2" color="primary"
            >0.25 ETH</v-chip
          >
        </div>
      </v-container>
    </v-card-text> -->
    </v-card>
  </v-lazy>
</template>

<script>
import { fileType, getFileType } from "../../store/tools";
// import { IPFS_MEDIA_URL } from "../../store/config";

// import { videoPlayer } from 'vue-md-player'
// import 'vue-md-player/dist/vue-md-player.css'

export default {
  name: "post-card",
  components: {
    // vuePlayer
    // "video-player": videoPlayer
    // "video-player": VueCoreVideoPlayer
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    forSale: {
      type: Boolean,
      default: false,
    },
    price: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    mediaType() {
      var ipfs_images = Object.values(this.post.ipfs_images);
      if (ipfs_images.length > 0) {
        return fileType(ipfs_images[0].ext);
      } else {
        return getFileType(this.post.media_files[0].file_name);
      }
    },
  },
  methods: {
    fileType,
  },
};
</script>
<style>
video {
  object-fit: cover !important;
}

.start-button {
  border: none !important;
}

.start-button i {
  font-size: 50px !important;
}
</style>
