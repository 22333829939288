<template>
  <v-dialog v-model="share_dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="text--primary">
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        <v-container>
        <v-row justify="space-between">
        Share this Post
        <v-btn icon small outlined @click="share_dialog = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        </v-row>
        </v-container>
      </v-card-title>
      
      <v-divider></v-divider>

      <v-card-text class="py-5 py-md-10 px-5 px-md-10">
        <v-container>
          <v-row justify="space-between" align="center" class="px-sm-5">
            <div class="text-center">
              <v-btn icon outlined @click="copyURL()">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
              <div class="mt-2">Copy</div>
            </div>

            <ShareNetwork
              network="facebook"
              style="text-decoration: none !important; color: inherit"
              :url="url"
              :title="post.title"
              hashtags="artemis-vision,artemis-media"
            > 
            <div class="text-center">
              <v-btn icon outlined>
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <div class="mt-2">Facebook</div>
            </div>
            </ShareNetwork>

            <!-- <ShareNetwork
              network="instagram"
              style="text-decoration: none !important; color: inherit"
              :url="url"
              :title="post.title"
              hashtags="artemis-vision,artemis-media"
            > 
            <div class="text-center">
              <v-btn icon outlined>
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <div class="mt-2">Instagram</div>
            </div>
            </ShareNetwork> -->

            <ShareNetwork
              network="telegram"
              style="text-decoration: none !important; color: inherit"
              :url="url"
              :title="post.title"
              hashtags="artemis-vision,artemis-media"
            >
              <div class="text-center">
                <v-btn icon outlined>
                  <v-icon>$si-telegram</v-icon>
                </v-btn>
                <div class="mt-2">Telegram</div>
              </div>
            </ShareNetwork>

            <ShareNetwork
              network="twitter"
              style="text-decoration: none !important; color: inherit"
              :url="url"
              :title="post.title"
              hashtags="artemis-vision,artemis-media"
            >
              <div class="text-center">
                <v-btn icon outlined>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <div class="mt-2">Twitter</div>
              </div>
            </ShareNetwork>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { APP_URL } from "../store/config";

export default {
  name: "ShareButton",
  props: {
    post: Object,
    // required: true
  },
  data() {
    return {
      share_dialog: false,
      url: `${APP_URL}/#/post/${this.post.id}/${encodeURI(
        this.post.title
      )}`,
    };
  },
  methods: {
    copyURL() {
      navigator.clipboard
        .writeText(this.url)
        .then(() => {
          console.log("Copy successful");
        })
        .catch((error) => {
          console.log(`Copy failed! ${error}`);
        });
    },
  },
};
</script>