
import ARM from '../../abis/ARM.json'
import ARMEscrow from '../../abis/ARMEscrow.json'
import ARM_1155 from '../../abis/ARM_1155.json'
import ARMEscrow_1155 from '../../abis/ARMEscrow_1155.json'
import networks from '../../contracts/networks.json'
var Web3 = require("web3");
import * as tools from "../../store/tools";

export var arvContracts = {
    erc721: null,
    erc721Escrow: null,
    erc20: null,
    erc1155: null,
    erc1155Escrow: null
}


export async function loadBlockchain(provider = "ethereum", options = { networkId: null }) {
    await tools.loadWeb3(provider);

    const web3 = window.web3;
    if (!web3) {
        window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
        return
    }

    //revert
    // web3.eth.handleRevert = true

    // Load account
    const accounts = await web3.eth.getAccounts();

    var networkId = await web3.eth.net.getId();
    if (provider == 'web3auth') {
        networkId = parseInt(tools.web3auth.coreOptions.chainConfig.chainId, 16);
    }
    console.log("Found Network ID", networkId, parseInt(tools.web3auth.coreOptions.chainConfig.chainId, 16), networks);
    if (options.networkId && networkId != options.networkId) {
        console.log("Switching to Network", options.networkId);
        try {
            await window.web3.currentProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Web3.utils.toHex(options.networkId) }],
            });
            console.log("switched network")

        } catch (error) {
            console.log('Error:', error, "Network chain support needs to be added to wallet");
            return;
        }
        networkId = options.networkId;
    }
    console.log("proceding with request")
    if (networks[networkId]) {
        // erc 721
        arvContracts.erc721 = new web3.eth.Contract(ARM.abi, networks[networkId].address.arm);
        arvContracts.erc721Escrow = new web3.eth.Contract(ARMEscrow.abi, networks[networkId].address.arm_escrow);

        arvContracts.erc721.address = networks[networkId].address.arm
        arvContracts.erc721Escrow.address = networks[networkId].address.arm_escrow

        // erc 1155
        arvContracts.erc1155 = new web3.eth.Contract(ARM_1155.abi, networks[networkId].address.arm_1155);
        arvContracts.erc1155Escrow = new web3.eth.Contract(ARMEscrow_1155.abi, networks[networkId].address.arm_escrow_1155);

        arvContracts.erc1155.address = networks[networkId].address.arm_1155
        arvContracts.erc1155Escrow.address = networks[networkId].address.arm_escrow_1155

        // erc20
        // var arvAddress = "0x28fDA76721a8077A5dE802Ab0212849B8c38429E"; // mainnet
        // arvContracts.erc20 = new web3.eth.Contract(Artemis.abi, arvAddress);
        // arvContracts.erc20.options.address = arvAddress;

        // find better place for approval of token (likely at mint)
        // var isApprovedForAll;
        // var approveData;
        // if (options.token_type == 'single' && options.approveForSale) {
        //     isApprovedForAll = await arvContracts.erc721.methods.isApprovedForAll(accounts[0], arvContracts.erc721Escrow.address).call();
        //     console.log('Escrow isApprovedForAll', isApprovedForAll)
        //     if (!isApprovedForAll) {
        //         approveData = await arvContracts.erc721.methods.setApprovalForAll(arvContracts.erc721Escrow.address, true)
        //             .send({ from: accounts[0] })
        //         console.log(approveData)
        //     }
        // }
        // if (options.token_type == 'multiple' && options.approveForSale) {

        //     isApprovedForAll = await arvContracts.erc1155.methods.isApprovedForAll(accounts[0], arvContracts.erc1155Escrow.address).call();
        //     console.log('Escrow isApprovedForAll', isApprovedForAll)
        //     if (!isApprovedForAll) {
        //         approveData = await arvContracts.erc1155.methods.setApprovalForAll(arvContracts.erc1155Escrow.address, true)
        //             .send({ from: accounts[0] })
        //         console.log(approveData)
        //     }
        // }

        return { accounts: accounts }
    } else {
        window.alert("Error: Smart contract not deployed to detected network.");
    }
}
