<template>
  <div>
    <navbar></navbar>

    <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      :hide-overlay="true"
      :mini-variant="mini"
      class="d-none d-sm-flex"
      color="background"
      :permanent="$vuetify.breakpoint.smAndUp"
    >
      <template v-slot:prepend>
        <v-list-item :ripple="false" v-if="!mini" @click="mini = true" link>
          <v-list-item-icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
        <v-list-item :ripple="false" v-else @click="mini = false" link>
          <v-list-item-icon>
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <v-list height="100%" class="d-flex flex-column">
        <v-spacer></v-spacer>
        <v-list active-class="primary--text" nav>
          <v-list-item
            :ripple="false"
            active-class="primary--text"
            v-for="item in items"
            :key="item.title"
            :to="`/${item.link}`"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
      </v-list>
      <template v-slot:append>
        <v-list-item class="py-2" :selectable="false" to="/profile" v-if="loggedIn">
          <v-avatar color="primary" size="32">
            <h5 class="mb-0 white--text" v-if="!user.profile.profile_pic">
              <span v-if="user.username != null || user.username != ''">{{
                user.username[0].toUpperCase()
              }}</span
              ><span v-else>{{ user.email[0].toUpperCase() }}</span>
            </h5>
            <v-img sizes="30" contain v-else :src="user.profile.profile_pic"></v-img>
          </v-avatar>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="drawer"
      app
      right
      fixed
      v-if="loggedIn && $route.name != 'edit-profile' && $route.name != 'verification'"
      :hide-overlay="true"
      class="hidden-md-and-down"
      color="background"
      :permanent="$vuetify.breakpoint.lgAndUp"
    >
      <div class="mt-10">
        <profile-sidebar v-if="$route.name != 'profile'"></profile-sidebar>
        <div v-else class="px-3 py-4">
          <top-sidebar></top-sidebar>
        </div>
      </div>
    </v-navigation-drawer>

    <v-main>
      <v-divider></v-divider>
      <v-sheet color="background">
        <v-container class="pb-15 pb-sm-0">
          <!-- <v-sheet width="100%"> -->
          <router-view></router-view>
          <!-- </v-sheet> -->
        </v-container>
      </v-sheet>
    </v-main>

    <!-- Bottom Navigation -->
    <v-bottom-navigation
      fixed
      background-color="bg_color"
      color="primary"
      class="hidden-sm-and-up"
    >
      <v-btn icon to="/home" a>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn icon to="/search">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn
        fab
        height="50px"
        min-width="50px"
        class="primary-gradient"
        style="
          z-index: 100;
          bottom: 20px;
          border-radius: 100%;
          max-width: 50px !important;
          box-shadow: inherit;
        "
        color="primary"
        to="/create_post"
      >
        <!-- // "cropperjs-gif": "file:../../../Downloads/cropperjs-gif-1.1.24.tgz", -->

        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>

      <v-btn icon to="/explore">
        <v-icon>mdi-compass</v-icon>
      </v-btn>

      <v-btn icon to="/shopping">
        <v-icon>mdi-shopping</v-icon>
      </v-btn>
      <!-- <v-btn icon to="/profile" v-if="loggedIn == true">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn icon to="/login" v-else>
        <v-icon>mdi-account</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
  </div>
</template>

<script>
import Navbar from "../components/NavBar.vue";
import TopSidebar from "../views/SideBars/TopSidebar.vue";
import ProfileSidebar from "../views/SideBars/ProfileSidebar.vue";
import { mapState } from "vuex";

export default {
  components: {
    navbar: Navbar,
    ProfileSidebar,
    TopSidebar,
  },
  created() {
    console.log(this.user);
  },
  data() {
    return {
      drawer: true,
      items: [
        { title: "Home", icon: "mdi-home", link: "home" },
        { title: "Create", icon: "mdi-plus", link: "create_post" },
        { title: "Explore", icon: "mdi-compass", link: "explore" },
        { title: "Shop", icon: "mdi-shopping", link: "shopping" },
        { title: "Messages", icon: "mdi-chat", link: "#messages" },
        { title: "Notifications", icon: "mdi-bell", link: "#notifications" },
        { title: "Metaverse", icon: "mdi-hololens", link: "#metaverse" },
      ],
      mini: true,
    };
  },
  computed: mapState({
    user: (state) => state.user.profile ?? {},
    loggedIn: (state) => state.user.profile != null,
  }),
};
</script>

<style scoped>
.activeNav {
  color: blueviolet;
}
</style>
