<template>
  <div>
    <v-container fluid>
      <!-- <v-row> -->
        <div class="pa-5 pt-0 d-flex flex-column justify-center">
          <div class="mx-auto mb-2 text-center">
            <v-avatar id="avatar" size="100">
              <p white--text class="mb-0 ma-2" v-if="user.profile.profile_pic == ''">{{ user.username[0].toUpperCase() }}</p>
                <v-img sizes="90" class="rounded-full ma-2" contain v-else
                  :src="user.profile.profile_pic"
                ></v-img>
            </v-avatar>
            <div class="mt-2 mb-1">
              <h4 class="subtitle-1 font-weight-medium">{{ user.username }}</h4>
              <span class="body-2">{{ user.profile.first_name }}</span>
            </div>
            <v-btn to="/profile" depressed outlined small class="mt-1">View Profile</v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" small icon outlined v-bind="attrs" v-on="on"
                  ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                >
              </template>
              <v-list dense>
                <v-list-item
                  ripple
                  v-for="(item, index) in options"
                  :key="index"
                  @click="item.action"
                >
                  <v-list-item-title
                    ><span class="body-2">{{
                      item.title
                    }}</span></v-list-item-title
                  >
                </v-list-item>

              </v-list>
            </v-menu>
          </div>
          <div class="d-flex flex-column justify-center mt-5">
            <div class="d-flex justify-center align-center text-center">
              <div>
                <p class="subtitle-2 mb-0">
                  {{ posts.length }} <span class="body-2"> Posts</span>
                </p>
              </div>
              <v-divider class="mx-2" vertical></v-divider>

              <div>
                <p class="subtitle-2 mb-0">
                  {{ user.profile.followers }} <span class="body-2"> Followers</span>
                </p>
              </div>
              <v-divider class="mx-2" vertical></v-divider>
              <div>
                <p class="subtitle-2 mb-0">
                  {{ user.profile.followings }} <span class="body-2"> Followings</span>
                </p>
              </div>
            </div>
          </div>
          <v-row align="center" justify="center" class="mx-1 mt-8 mb-2">
            <v-divider></v-divider><span class="mx-2 body-2">POSTS</span
            ><v-divider></v-divider>
          </v-row>
          <div class="flex-grow-1">
            <v-row  v-if="posts.length > 0">
              <v-col v-for="(post, i) in posts" :key="post.title+i" cols="6" class="pa-1">
                <post-card
                  :title="post.title"
                  :post="post"
                  :forSale="post.forSale"
                  :price="post.price"
                ></post-card>
                <!-- <v-hover>
                  <template v-slot:default="{ hover }">
                    <div>
                      <v-img
                        class="rounded"
                        :src="post.media_files[0].url"
                        @click.stop="showPost(post)"
                      >
                        <v-scale-transition origin="center center 0">
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="#000000"
                          >
                            <v-container fill-height>
                            </v-container>
                          </v-overlay>
                        </v-scale-transition>
                      </v-img>
                    </div>
                  </template>
                </v-hover> -->
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-sheet color="transparent" height="100px" max-height="200px">
                  <v-container fill-height>
                    <v-row align="center" justify="center">
                      <v-col cols="12" class="text-center">
                        <v-icon color="primary">mdi-post</v-icon>
                        <p class="subtitle-1">No Posts created yet</p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </div>
      <!-- </v-row> -->
      <v-row justify="center" class="my-6">
        <v-btn color="primary" elevation="15" to="/create_post" dark width="70%"
          >Create Post</v-btn
        >
      </v-row>
    </v-container>
    <post-view v-if="showPostDialog" @close="(val) => showPostDialog = val" :open.sync="showPostDialog" :post="selectedPost"></post-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { BACKEND_URL,IPFS_MEDIA_URL } from "../../store/config";
import PostCard2 from "../../components/post/PostCard2.vue";
import PostView from '../../components/post/PostView.vue';
import { AUTH_LOGOUT } from "../../store/actions/auth";

export default {
  created() {
    this.loading = true;
    axios
      .get(BACKEND_URL + "/nft/collectable/?user=0".replace(0, this.user.id))
      .then((response) => {
        var resp = response.data;
        console.log(resp);
        var profile = this.user;
        this.posts = resp.results;
        this.recentPosts = this.posts.slice(0, 4);
        profile["posts"] = this.posts.length;
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  components: {
    PostView,
    "post-card": PostCard2,
  },
  data() {
    return {
      loading: false,
      selectedPost: null,
      showPostDialog: false,
      recentPosts: [],
      posts: [],
      IPFS_MEDIA_URL:IPFS_MEDIA_URL,
      options: [
        {
          title: "Change Password",
          action: () => {
            this.$router.push({
              name: "login",
              params: {
                resetPassword: true,
              },
            });
          },
        },
        { title: "Log Out", action: this.logout },
      ],
    };
  },
  methods: {
    logout() {
      console.log("Logout");
      console.log(this.$store.getters.getProfile);
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/home");
      });
    },
    showPost(post) {
      this.selectedPost = post;
      this.showPostDialog = true;
    },
  },
  computed: mapState({ user: state => state.user.profile??{} })
};
</script>

<style scoped>
#avatar {
  border: 2px solid #ce45f796;
}
</style>
