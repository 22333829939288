import { render, staticRenderFns } from "./AuthLayout.vue?vue&type=template&id=c64d6e50&scoped=true&"
var script = {}
import style0 from "@/assets/css/style.css?vue&type=style&index=0&id=c64d6e50&prod&scoped=true&lang=css&"
import style1 from "./AuthLayout.vue?vue&type=style&index=1&id=c64d6e50&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c64d6e50",
  null
  
)

export default component.exports