// import DashboardLayout from '@/layouts/DashboardLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';

import NormalLayout from '@/layouts/NormalLayout.vue';
import ProfileLayout from '@/layouts/ProfileLayout.vue';
import EditProfileLayout from '@/layouts/EditProfileLayout.vue';
// import CreatePostLayout from '@/layouts/CreatePostLayout.vue';


const routes = [
  {
    path: '/auth',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Auth/Login.vue')
      },
      {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Auth/TermsnCondition.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Auth/PrivacyPolicy.vue')
      },
      {
        path: '/login/:token',
        name: 'verify_account',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Auth/Login.vue')
      },
      {
        path: '/signup',
        name: 'signup',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Auth/Register.vue')
      },
      {
        path: '/reset_password/:token',
        name: 'reset_password',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Auth/ResetPassword.vue')
      },
    ]
  },
  {
    path: '/',
    component: NormalLayout,
    redirect: 'home',
    children: [
      {
        path: '/home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Home.vue')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Search/mobileSearch.vue')
      },
      {
        path: '/search/:query',
        name: 'searchResults',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Search/SearchResults.vue')
      },
      {
        path: '/explore',
        name: 'explore',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Explore/Explore.vue')
      },
      {
        path: '/shopping', // duplicated explore for now
        name: 'shopping',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Explore/Shopping.vue')
      },

      // User and Profile
      {
        path: '/profile',
        component: ProfileLayout,
        children: [
          {
            path: '/',
            name: 'profile',
            component: () => import(/* webpackChunkName: "demo" */ '../views/User/Profile.vue')
          },
          {
            path: '/:id/:username',
            name: 'user-profile2',
            props: true,
            component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserProfile.vue')
          },
          {
            path: '/edit',
            component: EditProfileLayout,
            // name: 'profile-settings',
            children: [
              {
                path: '/',
                name: 'edit-profile',
                props: true,
                component: () => import(/* webpackChunkName: "demo" */ '../views/User/EditProfile.vue')
              },
              {
                path: '/verification',
                name: 'verification',
                props: true,
                component: () => import(/* webpackChunkName: "demo" */ '../views/User/VerificationApply.vue')
              },
              {
                path: '/notifications',
                name: 'notifications',
                props: true,
                component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserProfile.vue')
              },

            ]
          },
        ]
      },
      {
        path: 'user/profile/:id/:username',
        name: 'user-profile',
        props: true,
        component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserProfile.vue')
      },

      // Create
      {
        path: '/create',
        name: 'create',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Create.vue')
      },

      // Posts and Collectables
      {
        path: '/create_post',
        name: 'create_post',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Post/PostTypes.vue')
      },
      {
        path: '/create_single',
        name: 'create-single',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Post/CreatePost.vue')
      },

      {
        path: '/create_multiple',
        name: 'create-multiple',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Post/CreatePost.vue')
      },
      {
        path: '/post/:id/:title',
        name: 'post-details',
        props: true,
        component: () => import(/* webpackChunkName: "demo" */ '../views/Post/PostDetails.vue')
      },
      {
        path: '/list_for_sale',
        name: 'list_for_sale',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Post/ReList.vue')
      },

      // Reels
      {
        path: '/create_reel',
        name: 'create_reel',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Reels/CreateReel.vue')
      },
      {
        path: '/iframe_reel',
        name: 'iframe_reel',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Reels/IFrameReels.vue')
      },
      // Stories

      // {
      //   path: '/reel/trim',
      //   name: 'trim_reel',
      //   component: () => import('../views/Post/reels/TrimVideo.vue')
      // },
    ]
  },
  // {
  //   path: '/stories',
  //   name: 'stories',
  //   component: () => import('../views/Stories/Story.vue')
  // },
  // {
  //   path: '/create_reel',
  //   name: 'create_reel',
  //   component: () => import(/* webpackChunkName: "demo" */ '../views/Reels/CreateReel.vue')
  // },
];


export default routes;

